// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "B_vy d_bD";
export var storyRowWrapper = "B_hx d_hx d_bK";
export var storyLeftWrapper = "B_vz d_bz d_bP";
export var storyWrapperFull = "B_vB d_cD";
export var storyWrapperFullLeft = "B_qZ d_cD d_bz d_bP d_bD";
export var contentWrapper = "B_mv d_hy";
export var storyLeftWrapperCenter = "B_vC d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "B_vD d_hF";
export var storyHeader = "B_vF d_hD d_w d_cs";
export var storyHeaderCenter = "B_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "B_hB d_hB d_by d_dw";
export var storyBtnWrapper = "B_vG d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "B_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "B_rj d_fg d_Z";
export var imageWrapperFull = "B_vH d_w d_H d_bf d_Z";
export var SubtitleSmall = "B_pd z_pd z_tx z_tK";
export var SubtitleNormal = "B_pf z_pf z_tx z_tL";
export var SubtitleLarge = "B_pg z_pg z_tx z_tM";
export var textLeft = "B_dv";
export var textCenter = "B_dw";
export var textRight = "B_dx";