// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "x_s2 d_gv d_cs";
export var heroHeaderCenter = "x_gw d_gw d_cs d_dw";
export var heroHeaderRight = "x_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "x_s3 d_gr d_cw";
export var heroParagraphCenter = "x_gs d_gs d_cw d_dw";
export var heroParagraphRight = "x_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "x_s4 d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "x_s5 d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "x_s6 d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "x_s7 d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "x_s8 d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "x_s9 z_s9";
export var heroExceptionNormal = "x_tb z_tb";
export var heroExceptionLarge = "x_tc z_tc";
export var Title1Small = "x_td z_td z_tx z_ty";
export var Title1Normal = "x_tf z_tf z_tx z_tz";
export var Title1Large = "x_tg z_tg z_tx z_tB";
export var BodySmall = "x_th z_th z_tx z_tR";
export var BodyNormal = "x_tj z_tj z_tx z_tS";
export var BodyLarge = "x_tk z_tk z_tx z_tT";