// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "y_tl d_gS d_cw d_dv";
export var quoteParagraphCenter = "y_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "y_gV d_gV d_cw d_dx";
export var quoteRowLeft = "y_tm d_bG";
export var quoteRowCenter = "y_tn d_bD";
export var quoteRowRight = "y_tp d_bH";
export var quoteWrapper = "y_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "y_gR d_gR";
export var quoteExceptionSmall = "y_tq z_tq";
export var quoteExceptionNormal = "y_tr z_tr";
export var quoteExceptionLarge = "y_ts z_ts";
export var quoteAuthorExceptionSmall = "y_tt z_tt";
export var quoteAuthorExceptionNormal = "y_tv z_tv";
export var quoteAuthorExceptionLarge = "y_tw z_tw";